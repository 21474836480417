<template>
  <VDialog :width="showUserSelect ? '750px' : undefined">
    <div class="flex justify-end">
      <VButton variant="text" color="neutral" @click="emit('close')"><VIcon name="Outline/x-mark"></VIcon></VButton>
    </div>
    <div class="flex flex-col items-center mb-8 gap-y-4 mx-2">
      <VIcon v-if="!showUserSelect" name="Outline/exclamation-circle" color="red" size="6xl"/>
      <VHeading level="6" color="neutral/600" medium >{{modalTitle}}</VHeading>
      <VSpan v-if="showUserSelect">{{ modalBody }}</VSpan>
    </div>
    <template v-if="!showUserSelect">
      <ul class="mb-8">
        <li v-for="(user, index) in usersToArchive" :key="index" class="ml-4">
          <div class="flex items-center gap-x-2">
            <VButton icon variant="text" size="xs" color="neutral" @click="removeUser(user.id)"   @mouseover="toggleHovering(index, true)" @mouseleave="toggleHovering(index, false)" @focusin="toggleHovering(index, true)"  @focusout="toggleHovering(index, false)">
              <VIcon :color="isHovering[index] ? 'neutral' : 'neutral/450'" :name="isHovering[index] ? 'Outline/x-mark' : 'Solid/document-text'" size="xs"/>
              <VSpan>{{ user.email }}</VSpan>
            </VButton>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="max-h-[300px] min-w-[700px] bg-neutral-10 overflow-y-auto rounded border shadow-sm">
        <div class="grid grid-cols-10 gap-2 mt-2 mb-4 items-center px-4">
          <div class="col-start-1 col-end-4">
            <VSpan size="base" color="black" bold>{{ localize('headers.to_archive') }}</VSpan>
          </div>
          <div class="col-start-6 col-end-9">
            <VSpan size="base" color="black"  bold>{{ localize('headers.new_owner') }}</VSpan>
          </div>
          <div v-if="!savingChanges" class="col-start-10 col-end-11">
            <VButton v-if="usersToArchive?.some(user => user.ownerId) && !usersToArchive?.every(user => user.ownerId)" variant="text" size="xs" :class="'underline'" @click="applyAll">{{ localize('button.apply_all') }}</VButton>
            <VButton v-if="usersToArchive?.every(user => user.ownerId)" variant="text" size="xs" :class="'underline'" @click="clearAll">{{ localize('button.clear_all') }}</VButton>
          </div>
          <template v-for="(user, index) in usersToArchive" :key="index">
            <div class="col-start-1 col-end-6 hover:bg-primary-40 rounded p-2">
              <div class="flex gap-x-2 items-center">
                <VIcon v-if="user.saved" name="Outline/check" color="green" size="xs"/>
                <VIcon v-if="user.error" name="Outline/x-mark" color="red" size="xs"/>
                <VSpinner v-if="savingChanges && (!user.saved && !user.error)" size="xs"></VSpinner>
                <VSpan>{{ user.email }}</VSpan>
              </div>
            </div>
            <div class="col-start-6 col-end-11">
              <VSelect
                v-model="user.ownerId"
                :options="filteredActiveUsers"
                :placeholder="localize('select_placeholder')"
                :disabled="savingChanges || user.saved"
                search
              />
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="flex justify-end gap-x-6 mt-4">
      <VButton color="neutral" variant="outlined" @click="emit('close')">{{localize.global('general.cancel')}}</VButton>
      <VButton color="primary" :disabled="disableButton" @click="handleYesClick">{{buttonText}}</VButton>
    </div>
  </VDialog>  
</template>
<script lang="ts" setup>
import VButton from '@component-library/buttons/VButton.vue';
import VSelect from '@component-library/inputs/VSelect.vue';
import VHeading from '@component-library/labels/VHeading.vue';
import VIcon from '@component-library/labels/VIcon.vue';
import VSpinner from '@component-library/labels/VSpinner.vue';
import VSpan from '@component-library/labels/VSpan.vue';
import VDialog from '@component-library/dialogs/VDialog.vue';
import { computed, ref } from 'vue';
import BulkActionsApi from '~/api/Admin/Users/BulkActionsApi';
import { useLocalize } from '@component-utils/localization';

const props = defineProps<{
  activeUsers: {label: string, value: number}[]
  usersToArchive:{email: string, id: number, ownerId?: number, saved?: boolean, error?: boolean}[]
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const localize = useLocalize('admin.users.toggle_dialog')

const activeUsers = computed(()=>props.activeUsers)
const filteredActiveUsers = computed(()=>{
  const selectedUserIds = usersToArchive.value?.map(user => user.id) ?? []
  return activeUsers.value?.filter(user => !selectedUserIds.includes(user.value))
})
const usersToArchive = ref(props.usersToArchive)
const showUserSelect = ref(false)
const modalTitle = computed(()=> localize(showUserSelect.value ? 'title.ownership' : 'body.archive'))
const modalBody = computed(()=> localize(showUserSelect.value ? 'body.ownership' : 'body.archive'))
const buttonText = computed(()=> localize.global(showUserSelect.value ? 'general.confirm' : 'general.yes_word'))
const savingChanges = ref(false)
const disableButton = ref(false)
const isHovering = ref<{[userId: string]: boolean}>({})
const toggleHovering = (index:number, show: boolean) => isHovering.value[index] = show

const removeUser = (id:number) => {
  usersToArchive.value = usersToArchive.value?.filter(user => user.id !== id)
}

const handleYesClick = () => {
  if(showUserSelect.value) handleSave()
  else showUserSelect.value = true
}

const handleSave = async () => {
  if(!usersToArchive.value) return
  disableButton.value = true
  savingChanges.value = true
  Promise.all(
    usersToArchive.value.map(async (user) => await saveUser(user))
  )
  setTimeout(()=> window.location.reload(), 1000)
} 

const saveUser = async (user: {email: string, id: number, ownerId?: number, saved?: boolean, error?: boolean}) => {
  const userId = user.id
  const newOwnerId = user.ownerId ?? undefined
  const requestData = {bulk_action: 'archive', user_ids: [userId], ... (user.ownerId ? {owner_id: newOwnerId} : null)}
  try{
    const response = await BulkActionsApi.create<{archived_users_count: number}>({data: requestData})
    if(response.archived_users_count > 0) user.saved = true
    else user.error = true
  }catch(e) {
    avv_toast({message: localize('notices.error_saving'), type: 'error'})
  }
}

const applyAll = () => {
  if(!usersToArchive.value) return
  const usersWithOwner = usersToArchive.value.filter(user => user.ownerId)
  if(!usersWithOwner.length) return
  const ownerIdToAply = usersWithOwner[0].ownerId
  usersToArchive.value.forEach(user => user.ownerId = ownerIdToAply)
}

const clearAll = () => {
  if(!usersToArchive.value) return
  usersToArchive.value.forEach(user => user.ownerId = undefined)
}

</script>
